<template>
    <section>
        <vue-headful title="FLPN Aviasolutions - List Clients"></vue-headful>
        <nav class="level">
            <!-- Left side -->
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">List Clients</h1>
                </div>
            </div>
            <!-- Right side -->
            <div class="level-right">
                <p class="level-item">
                    <router-link :to="'/clients/create'" class="button is-dark" v-if="$auth.check(['admin'])">
                        <span class="icon">
                            <i class="fas fa-plus"></i>
                        </span>
                        <span>Client</span>
                    </router-link>
                </p>
            </div>
        </nav>
      <contenedor>
        <b-table
            paginated
            backend-pagination
            backend-sorting
            :data="pagination.records"
            :loading="pagination.loading"
            :total="pagination.total"
            :per-page="pagination.perPage"
            :default-sort-direction="pagination.defaultSortOrder"
            :default-sort="[pagination.sortField, pagination.sortOrder]"
            @page-change="onPageChange"
            @sort="onSort">
            <template slot-scope="props">
                <b-table-column field="name" label="Name" sortable>
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column field="description" label="Description" sortable>
                    {{ props.row.description }}
                </b-table-column>
                <b-table-column field="operator_name" label="Operator Name" sortable>
                    {{ props.row.operator_name }}
                </b-table-column>
                <b-table-column field="expiration_period" label="Expiration Period" sortable>
                    {{ props.row.expiration_period }}
                </b-table-column>
                <b-table-column field="is_suspended" label="Suspended" sortable>
                    {{ props.row.is_suspended ? 'YES' : 'NO' }}
                </b-table-column>
                <b-table-column width="80" label="Actions">
                    <div class="field has-addons">
                        <p class="control">
                            <b-tooltip label="Edit" type="is-dark">
                                <router-link :to="`/clients/${props.row.id}/edit`" class="button is-small is-link">
                                    <b-icon pack="far" icon="edit"></b-icon>
                                </router-link>
                            </b-tooltip>
                        </p>
                    </div>
                </b-table-column>
            </template>
        </b-table>
      </contenedor>
    </section>
</template>
<script>
import ClientsService from "../../services/clients.service";
import Contenedor from "@/components/Contenedor";

export default {
    name: 'ClientIndex',
  components: {Contenedor},
  data() {
        return {
            clientsService: new ClientsService(),
            pagination: {
                records: [],
                total: 0,
                loading: false,
                sortField: 'name',
                sortOrder: 'asc',
                defaultSortOrder: 'desc',
                page: 1,
                perPage: 20
            }
        };
    },
    methods: {
        paginate() {
            const params = {
                sort_field: this.pagination.sortField,
                sort_order: this.pagination.sortOrder,
                per_page: this.pagination.perPage,
                page: this.pagination.page
            };
            this.pagination.loading = true;
            this.clientsService.paginate(params).then(
                ({data}) => {
                    // api.themoviedb.org manage max 1000 pages
                    this.pagination.records = data.data;                    
                    this.pagination.total = data.meta.total;                    
                    this.pagination.loading = false
                },
                () => {
                    this.pagination.records = []
                    this.pagination.total = 0
                    this.pagination.loading = false
                    this.$buefy.toast.open({
                        message: 'An error ocurred while paginating!!!',
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                }
            );
        },
        /*
        * Handle page-change event
        */
        onPageChange(page) {
            this.pagination.page = page;
            this.paginate();
        },
        /*
        * Handle sort event
        */
        onSort(field, order) {
            this.pagination.sortField = field;
            this.pagination.sortOrder = order;
            this.paginate();
        }
    },
    mounted() {
        this.paginate();
    }
}
</script>